import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {iif, mergeMap, Observable, of} from 'rxjs';
import {ConfigService} from "../service/config.service";

@Injectable({
  providedIn: 'root'
})
export class PortSelectedGuard implements CanActivateChild, CanActivate {
  constructor(
    protected router: Router,
    protected configService: ConfigService
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.configsOk();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.configsOk();

  }


  private configsOk(): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return of(this.configService.selectedPort).pipe(
      mergeMap(x => iif(() => !!x, of(true), of(this.router.createUrlTree(['/', 'port-selection']))))
    );
    ;
  }
}
