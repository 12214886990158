<section class="h-full w-full py-4 px-10 bg-gray-50 ">
  <div class="w-full flex flex-row justify-between  mb-8  shadow sticky backdrop-saturate-200 backdrop-blur-2xl bg-white/80 rounded-lg ">
    <app-logo class="ml-[4rem] mt-2"></app-logo>
  </div>
  <main
    class="relative w-full h-full max-h-screen transition-all duration-200 ease-soft-in-out  rounded-xl ps ">
    <router-outlet></router-outlet>
  </main>


</section>
