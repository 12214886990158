import { Component } from '@angular/core';
import {ConfigService} from "../../service/config.service";
import {PortDashboardComponent} from "../../port/port-dashboard/port-dashboard.component";
import {PortDTO} from "../../service/dto";
import {Router} from "@angular/router";

@Component({
  selector: 'app-port-selection',
  templateUrl: './port-selection.component.html',
  styleUrl: './port-selection.component.scss'
})
export class PortSelectionComponent {
  portSelected: PortDTO
  constructor(private configService: ConfigService,
              private router: Router) {
    this.portSelected = this.configService.selectedPort
  }

  changeSelectedPort() {
    this.configService.selectedPort = null
    this.router.navigate(['/', 'port-selection'])
  }
}
