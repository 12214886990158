<section class="h-full w-full  bg-gray-50 flex flex-row">
  <aside class=" w-72 max-w-80 flex flex-col items-center  p-0 my-4 overflow-y-auto transition-all duration-200
          border-0 shadow-none  ease-soft-in-out   rounded-2xl translate-x-0  ">
    <app-logo></app-logo>
    <div class="h-[1px] w-full  mt-1 bg-gradient-to-r from-transparent via-black/40 to-transparent " id="sep"></div>
    <app-menu></app-menu>
  </aside>

  <main class="relative flex flex-col w-full h-full max-h-screen transition-all duration-200 ease-soft-in-out  rounded-xl ps p-6">
    <app-header></app-header>
    <div class="flex-grow-1">
      <router-outlet></router-outlet>
    </div>
  </main>


</section>


<!--<section class="h-full w-full flex flex-col">
  <header>
    <app-header></app-header>
  </header>
  <section class="flex flex-row h-full overflow-hidden">
    <nav class="h-full">
      <app-menu></app-menu>
    </nav>
    <section class=" w-full  ">
      <div class="p-8 mt-2 w-full max-h-full overflow-y-auto">
        <router-outlet></router-outlet>
      </div>
    </section>
  </section>-->
