import {Component} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
  username: Observable<string>;

  constructor(private authService: AuthService,
              private router: Router) {
    this.username = this.authService.getUsername()
  }

  logout() {
    this.authService.logout()
  }

  goToManageAccount() {
    window.location.href = environment.keycloak.url + "/realms/triton/account/"
  }
}
