import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard} from "./guard/AuthGuard";
import {PublicLayoutComponent} from "./public-layout/public-layout.component";
import {PortSelectedGuard} from "./guard/PortSelectedGuard";
import {PortListComponent} from "./port-list/port-list.component";
import {PortDashboardComponent} from "./port/port-dashboard/port-dashboard.component";


const routes: Routes = [

  {
    path: 'public',
    pathMatch: 'prefix',
    component: PublicLayoutComponent,
    children: [
      {path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},

    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/port-selection'
  },
  {
    path: 'port-selection',
    pathMatch: 'full',
    component: PublicLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: PortListComponent
      }
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    //canMatch: [AuthGuard],
    canActivateChild: [AuthGuard, PortSelectedGuard],
    children: [
      {
        path: 'dashboard',
        component: PortDashboardComponent
      },
      {
        path: 'boats',
        canActivate: [PortSelectedGuard],
        loadChildren: () => import('./boats/boats.module').then(m => m.BoatsModule)
      },
      {
        path: 'reservations',
        canActivate: [PortSelectedGuard],
        loadChildren: () => import('./reservations/reservations.module').then(m => m.ReservationsModule)
      },
      {
        path: 'port',
        canActivate: [PortSelectedGuard],
        loadChildren: () => import('./port/port.module').then(m => m.PortModule)
      },
      {
        path: 'settings',
        canActivate: [PortSelectedGuard],
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
    ],
  },

];
const routerConfig: ExtraOptions = {
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, routerConfig),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
