import {Component} from '@angular/core';
import {faAngleDown, faCircle, faClipboardList, faCogs, faMap, faSailboat} from "@fortawesome/free-solid-svg-icons";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {AuthService} from "../../service/auth.service";


interface MenuItem {
  id: string,
  name: string,
  url?: string,
  icon?: IconDefinition,
  children?: MenuItem[],
  selected?: boolean
  open?: boolean
  hide?: Function
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  currentActive: MenuItem[] = []
  menuItems: MenuItem[] = [


    {
      id: 'dashboard',
      name: 'Dashboard',
      url: '/dashboard',
      icon: faMap,
      children: null
    },
    {
      id: 'boats',
      name: 'Anagrafiche',
      // url: '/boats',
      icon: faSailboat,
      children: [
        {
          id: 'Imbarcazioni',
          name: 'Imbarcazioni',
          url: '/boats',
          icon: faCircle,
        },
        {
          id: 'Armatori',
          name: 'Armatori',
          url: '/boats/shipowners',
          icon: faCircle,
        },
        {
          id: 'Comandanti',
          name: 'Comandanti',
          url: '/boats/skippers',
          icon: faCircle,
        },
      ]
    },
    {
      id: 'prenotazioni',
      name: 'Prenotazioni',
      // url: '/boats',
      icon: faClipboardList,
      children: [
        {
          id: 'richieste',
          name: 'Richieste',
          url: '/reservations/reservation-requests',
          icon: faCircle,
        },
        {
          id: 'prenotazioni',
          name: 'Prenotazioni',
          url: '/port/detail',
          icon: faCircle,
        },

      ]
    },
    {
      id: 'Impostazioni',
      name: 'Impostazioni',
      // url: '/boats',
      icon: faCogs,
      hide: () => !this.isAdmin,
      children: [
        {
          id: 'Gestione Utenti',
          name: 'Gestione Utenti',
          url: '/settings/users',
          icon: faCircle,
        },
        {
          id: 'Inserisci Tariffe',
          name: 'Configurazione prezzi',
          url: '/settings/prices',
          icon: faCircle,
        },
      ]
    },
  ]

  isAdmin: boolean = false

  constructor(private router: Router, private auth: AuthService) {
    this.auth.isAdmin().subscribe(x => this.isAdmin = x)
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.enableHierarchy(this.menuItems, this.router.url)
      });
  }

  setMenu(item: MenuItem) {
    item.open = !item.open
    this.enableHierarchy(this.menuItems, item.url)
  }

  enableHierarchy(items: MenuItem[], url: string) {
    if (!url || !items)
      return
    let found = null;
    for (let node of items) {
      node.selected = false;
      node.open = false;
      if (url.startsWith(node.url)) {
        found = node;
      } else {
        const c = this.enableHierarchy(node.children, url);
        if (c?.selected) {
          node.selected = true;
          node.open = true;
          found = node;
        }
      }
    }
    if (found) {
      found.selected = true
    }
    return found


  }


  protected readonly faAngleDown = faAngleDown;
}
