@if (data|async; as d) {
  <div class="mt-2 mb-4 font-bold">Seleziona il porto che vuoi visualizzare</div>
  <div class="grid grid-cols-2 gap-4 ">
    @for (item of d; track item.id) {
      <app-port-card [port]="item" (onPortClick)="goToDashboard($event)"></app-port-card>
    }
  </div>
} @else {
  <div class="flex justify-center items-center w-full h-full">
    <app-spinner size="XL"></app-spinner>
  </div>
}

